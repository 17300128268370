import React, {useState} from 'react';
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router-dom";
import { LoginIcon } from "@heroicons/react/solid";

export const PortalDashboardPage = () => {
    const navigate = useNavigate();
    let [showFooter, setShowFooter] = useState(false);
    const handleContentScroll = (e) => {
        let show = e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight;
        if (show) setShowFooter(() => showFooter = show)
        else setShowFooter(() => showFooter = false)
    }
    const handleAdminClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        navigate('/admin');
    }
    const handleCommunityClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        navigate('/community');
    }
    const handleLoginClick = e => {
        e.preventDefault();
        e.stopPropagation();
        navigate('/login');
    }
    React.useEffect(() => {
        const jquery = document.createElement('script');
        jquery.src = '/admin/js/jquery-3.6.0-min.js';
        jquery.async = true;
        document.body.appendChild(jquery);
        const bootstrap = document.createElement('script');
        bootstrap.src = '/admin/js/bootstrap.min.js';
        bootstrap.async = true;
        document.body.appendChild(bootstrap);
        const main = document.createElement('script');
        main.src = '/assets/js/main.js';
        main.async = true;
        document.body.appendChild(main);
        return () => {
            document.body.removeChild(jquery);
            document.body.removeChild(bootstrap);
            document.body.removeChild(main);
        }
    }, [])
    return (
        <div>
            <div className="main-dashboard">
                <Helmet>
                    <link rel="stylesheet" href="/assets/css/main.css"/>
                    <link rel="stylesheet" href="/assets/css/fontawesome-all.min.css"/>
                    <link rel="stylesheet" href="/assets/css/bootstrap.min.css"/>
                    <link rel="stylesheet" href="/assets/css/slick.css"/>
                    <link rel="stylesheet" href="/assets/css/responsive.css"/>
                </Helmet>
                <div className="sidebar collapsed">
                    <i className="fas fa-times"/>
                    <div className="logo-wrap">
                        <div className="logo-inner light-logo">
                            <img className="logo" src="/assets/img/logo-light.png" alt=""/>
                            <img className="logo-text" src="/assets/img/logo-text-light.png" alt=""/>
                        </div>
                        <div className="logo-inner dark-logo">
                            <img className="logo" src="/assets/img/logo-dark.png" alt=""/>
                            <img className="logo-text" src="/assets/img/logo-text-dark.png" alt=""/>
                        </div>
                    </div>
                    <div className="sidebar-menu-wrap">
                        <div className="menu-collapse-trigger">
                            <span/>
                            <span/>
                            <span/>
                        </div>
                        <ul className="navigation">
                            <li><a className="active" href="#"><span className="menu-icon"><img
                                src="/assets/img/sidebar/menu-icon1.png" alt=""/></span> <span
                                className="menu-text">Dashboard</span></a></li>
                            <li><a href="#"><span className="menu-icon"><img src="/assets/img/sidebar/menu-icon2.png"
                                                                             alt=""/></span> <span
                                className="menu-text">Leases</span></a></li>
                            <li><a href="#" onClick={(e) => handleAdminClick(e)}><span className="menu-icon"><img src="/assets/img/sidebar/menu-icon2.png"
                                                                             alt=""/></span> <span
                                className="menu-text">Admin</span></a></li>
                            <li><a href="#"><span className="menu-icon"><img src="/assets/img/sidebar/menu-icon3.png"
                                                                             alt=""/></span> <span
                                className="menu-text">Tenants</span></a></li>
                            <li><a href="#"><span className="menu-icon"><img src="/assets/img/sidebar/menu-icon4.png"
                                                                             alt=""/></span> <span
                                className="menu-text">Finance</span></a></li>
                            <li><a href="#"><span className="menu-icon"><img src="/assets/img/sidebar/menu-icon5.png"
                                                                             alt=""/></span> <span
                                className="menu-text">Calendar</span></a></li>
                            <li><a href="#"><span className="menu-icon"><img src="/assets/img/sidebar/menu-icon6.png"
                                                                             alt=""/></span> <span
                                className="menu-text">Issue Tracker</span></a></li>
                            <li><a href="#"><span className="menu-icon"><img src="/assets/img/sidebar/menu-icon7.png"
                                                                             alt=""/></span> <span
                                className="menu-text">Assets</span></a></li>
                            <li><a href="#"><span className="menu-icon"><img src="/assets/img/sidebar/menu-icon8.png"
                                                                             alt=""/></span> <span
                                className="menu-text">Warranties &amp; Insurance</span></a></li>
                            <li><a href="#"><span className="menu-icon"><img src="/assets/img/sidebar/menu-icon9.png"
                                                                             alt=""/></span> <span
                                className="menu-text">Capital Improvements</span></a></li>
                            <li><a href="#"><span className="menu-icon"><img src="/assets/img/sidebar/menu-icon10.png"
                                                                             alt=""/></span> <span
                                className="menu-text">Vendors</span></a></li>
                            <li><a href="#"><span className="menu-icon"><img src="/assets/img/sidebar/menu-icon11.png"
                                                                             alt=""/></span> <span
                                className="menu-text">Front Desk</span></a></li>
                            <li><a href="#"><span className="menu-icon"><img src="/assets/img/sidebar/menu-icon12.png"
                                                                             alt=""/></span> <span
                                className="menu-text">Security</span></a></li>
                            <li><a href="#"><span className="menu-icon"><img src="/assets/img/sidebar/menu-icon13.png"
                                                                             alt=""/></span> <span
                                className="menu-text">Staff</span></a></li>
                            <li><a href="#"><span className="menu-icon"><img src="/assets/img/sidebar/menu-icon14.png"
                                                                             alt=""/></span> <span
                                className="menu-text">Communication</span></a></li>
                            <li><a href="#"><span className="menu-icon"><img src="/assets/img/sidebar/menu-icon15.png"
                                                                             alt=""/></span> <span
                                className="menu-text">Units</span></a></li>
                            <li><a href="#"><span className="menu-icon"><img src="/assets/img/sidebar/menu-icon16.png"
                                                                             alt=""/></span> <span
                                className="menu-text">Files</span></a></li>
                            <li><a href="#"><span className="menu-icon"><img src="/assets/img/sidebar/menu-icon17.png"
                                                                             alt=""/></span> <span
                                className="menu-text">Reports</span></a></li>
                            <li><a href="#"><span className="menu-icon"><img src="/assets/img/sidebar/menu-icon18.png"
                                                                             alt=""/></span> <span
                                className="menu-text">Activity Stream</span></a></li>
                            <li><a href="#"><span className="menu-icon"><img src="/assets/img/sidebar/menu-icon19.png"
                                                                             alt=""/></span> <span
                                className="menu-text">System Seetings</span></a></li>
                            <li><a href="#" onClick={e => handleCommunityClick(e)}><span className="menu-icon"><img src="/assets/img/sidebar/menu-icon20.png"
                                                                             alt=""/></span> <span
                                className="menu-text">Community Forum &amp; User Guides</span></a></li>
                        </ul>
                    </div>
                </div>
                <div className="main-content collapsed" onScroll={e => handleContentScroll(e)}>
                    {/* header bar */}
                    <section className="header-bar">
                        <div className="row align-items-center">
                            <div className="col-xl-3 col-md-12 col-2">
                                <div className="property-item">
                                    <div className="property-img">
                                        <img src="/assets/img/property.png" alt=""/>
                                    </div>
                                    <div className="property-select">
                                        <select>
                                            <option>New Heights</option>
                                            <option>New Heights2</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="mobile-logo">
                                    <img className="img-fluid logo-light" src="/assets/img/logo-light.png" alt=""/>
                                    <img className="img-fluid logo-dark" src="/assets/img/logo-dark.png" alt=""/>
                                </div>
                            </div>
                            <div className="col-xl-9 col-md-12 col-10">
                                <div className="header-bar-right">
                                    <ul className="header-icons">
                                        <li className="dropdown">
                                            <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink1"
                                               data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src="/assets/img/topbar-icon1.png" alt=""/>
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink1">
                                                <span className="triangle-arrow"/>
                                            </div>
                                        </li>
                                        <li className="dropdown">
                                            <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink2"
                                               data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src="/assets/img/topbar-icon2.png" alt=""/>
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink2">
                                                <span className="triangle-arrow"/>
                                            </div>
                                        </li>
                                        <li className="dropdown">
                                            <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink3"
                                               data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src="/assets/img/topbar-icon3.png" alt=""/>
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink3">
                                                <span className="triangle-arrow"/>
                                            </div>
                                        </li>
                                        <li className="dropdown right">
                                            <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink4"
                                               data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src="/assets/img/topbar-icon4.png" alt=""/>
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink4">
                                                <span className="triangle-arrow"/>
                                            </div>
                                        </li>
                                        <li className="dropdown right">
                                            <a className="dropdown-toggle" onClick={e => handleLoginClick(e)} role="button" id="dropdownMenuLink5"
                                               data-bs-toggle="dropdown" aria-expanded="false">
                                                <LoginIcon className="text-gray-500 font-light"/>
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink4">
                                                <span className="triangle-arrow"/>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="account-details">
                                        <div className="account-author">
                                            <img src="/assets/img/author.png" alt=""/>
                                        </div>
                                        <select>
                                            <option>Anddy’s Makeover</option>
                                            <option>Anddy’s Makeover2</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* search wraper  */}
                    <section className="search-wraper">
                        <div className="row">
                            <div className="col-xl-8 order-lg-2">
                                <div className="search-right-side-wrap">
                                    <div className="mobile-menu-trigger">
                                        <span/>
                                        <span/>
                                        <span/>
                                    </div>
                                    <div className="serch-bar-side">
                                        <div className="form-group search">
                                            <input type="search" name id placeholder="Type here to Search..."/>
                                            <i className="fas fa-search"/>
                                        </div>
                                    </div>
                                    <label className="theme-switch">
                                        <span className="switch-dark">dark</span>
                                        <input type="checkbox" id="check-slider"/>
                                        <span className="check-slider round"/>
                                        <span className="switch-light">light</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-xl-4 order-lg-1">
                                <div className="welcome-wrap">
                                    <div className="welcome-inner">
                                        <h4>Welcome Back <span>Anddy’s Makeover</span></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* service wraper */}
                    <section className="service-area">
                        <div className="row">
                            {/* single service  */}
                            <div className="col-xl-3 col-lg-4 col-md-6 col-6">
                                <div className="single-service">
                                    <div className="service-content">
                                        <img src="/assets/img/service/service-icon1.png" alt=""
                                             className="service-icon"/>
                                        <h3>13</h3>
                                        <h5>Account Due</h5>
                                    </div>
                                    <div className="service-bottom">
                                        <a href="#" className="learn-more">Learn More <img
                                            src="/assets/img/long-arrow.png" alt=""/></a>
                                    </div>
                                </div>
                            </div>
                            {/* single service  */}
                            <div className="col-xl-3 col-lg-4 col-md-6 col-6">
                                <div className="single-service service-style-2">
                                    <div className="service-content">
                                        <img src="/assets/img/service/service-icon2.png" alt=""
                                             className="service-icon"/>
                                        <h3>235</h3>
                                        <h5>Rent Per Month</h5>
                                    </div>
                                    <div className="service-bottom">
                                        <a href="#" className="learn-more">Learn More <img
                                            src="/assets/img/long-arrow.png" alt=""/></a>
                                    </div>
                                </div>
                            </div>
                            {/* single service  */}
                            <div className="col-xl-3 col-lg-4 col-md-6 col-6">
                                <div className="single-service service-style-3">
                                    <div className="service-content">
                                        <img src="/assets/img/service/service-icon2.png" alt=""
                                             className="service-icon"/>
                                        <h3>44</h3>
                                        <h5>Total Rent Collected</h5>
                                    </div>
                                    <div className="service-bottom">
                                        <a href="#" className="learn-more">Learn More <img
                                            src="/assets/img/long-arrow.png" alt=""/></a>
                                    </div>
                                </div>
                            </div>
                            {/* single service  */}
                            <div className="col-xl-3 col-lg-4 col-md-6 col-6">
                                <div className="single-service service-style-4">
                                    <div className="service-content">
                                        <img src="/assets/img/service/service-icon3.png" alt=""
                                             className="service-icon"/>
                                        <h3>25</h3>
                                        <h5>Total Capital Improvement Cost</h5>
                                    </div>
                                    <div className="service-bottom">
                                        <a href="#" className="learn-more">Learn More <img
                                            src="/assets/img/long-arrow.png" alt=""/></a>
                                    </div>
                                </div>
                            </div>
                            {/* single service  */}
                            <div className="col-xl-3 col-lg-4 col-md-6 col-6">
                                <div className="single-service service-style-5">
                                    <div className="service-content">
                                        <img src="/assets/img/service/service-icon4.png" alt=""
                                             className="service-icon"/>
                                        <h3>07</h3>
                                        <h5>Account Credits</h5>
                                    </div>
                                    <div className="service-bottom">
                                        <a href="#" className="learn-more">Learn More <img
                                            src="/assets/img/long-arrow.png" alt=""/></a>
                                    </div>
                                </div>
                            </div>
                            {/* single service  */}
                            <div className="col-xl-3 col-lg-4 col-md-6 col-6">
                                <div className="single-service service-style-6">
                                    <div className="service-content">
                                        <img src="/assets/img/service/service-icon5.png" alt=""
                                             className="service-icon"/>
                                        <h3>02</h3>
                                        <h5>Total Vendor Expenses</h5>
                                    </div>
                                    <div className="service-bottom">
                                        <a href="#" className="learn-more">Learn More <img
                                            src="/assets/img/long-arrow.png" alt=""/></a>
                                    </div>
                                </div>
                            </div>
                            {/* single service  */}
                            <div className="col-xl-3 col-lg-4 col-md-6 col-6">
                                <div className="single-service service-style-7">
                                    <div className="service-content">
                                        <img src="/assets/img/service/service-icon6.png" alt=""
                                             className="service-icon"/>
                                        <h3>39</h3>
                                        <h5>Total Maintenance Cost</h5>
                                    </div>
                                    <div className="service-bottom">
                                        <a href="#" className="learn-more">Learn More <img
                                            src="/assets/img/long-arrow.png" alt=""/></a>
                                    </div>
                                </div>
                            </div>
                            {/* single service  */}
                            <div className="col-xl-3 col-lg-4 col-md-6 col-6">
                                <div className="single-service service-style-8">
                                    <div className="service-content">
                                        <img src="/assets/img/service/service-icon7.png" alt=""
                                             className="service-icon"/>
                                        <h3>09</h3>
                                        <h5>Open Maintenance and Concern</h5>
                                    </div>
                                    <div className="service-bottom">
                                        <a href="#" className="learn-more">Learn More <img
                                            src="/assets/img/long-arrow.png" alt=""/></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* slider area  */}
                    <section className="property-slider-area">
                        {/* single property-slider  */}
                        <div className="single-property-slider">
                            <div className="p-slider-icon">
                                <img src="/assets/img/property-slider/p-slider1.png" alt=""/>
                            </div>
                            <div className="p-slider-content">
                                <h4>04</h4>
                                <p>Units</p>
                            </div>
                        </div>
                        {/* single property-slider  */}
                        <div className="single-property-slider">
                            <div className="p-slider-icon">
                                <img src="/assets/img/property-slider/p-slider2.png" alt=""/>
                            </div>
                            <div className="p-slider-content">
                                <h4>02</h4>
                                <p>Tenants</p>
                            </div>
                        </div>
                        {/* single property-slider  */}
                        <div className="single-property-slider">
                            <div className="p-slider-icon">
                                <img src="/assets/img/property-slider/p-slider3.png" alt=""/>
                            </div>
                            <div className="p-slider-content">
                                <h4>01</h4>
                                <p>Property Managers</p>
                            </div>
                        </div>
                        {/* single property-slider  */}
                        <div className="single-property-slider">
                            <div className="p-slider-icon">
                                <img src="/assets/img/property-slider/p-slider4.png" alt=""/>
                            </div>
                            <div className="p-slider-content">
                                <h4>02</h4>
                                <p>Staf</p>
                            </div>
                        </div>
                        {/* single property-slider  */}
                        <div className="single-property-slider">
                            <div className="p-slider-icon">
                                <img src="/assets/img/property-slider/p-slider5.png" alt=""/>
                            </div>
                            <div className="p-slider-content">
                                <h4>02</h4>
                                <p>Vendor</p>
                            </div>
                        </div>
                        {/* single property-slider  */}
                        <div className="single-property-slider">
                            <div className="p-slider-icon">
                                <img src="/assets/img/property-slider/p-slider1.png" alt=""/>
                            </div>
                            <div className="p-slider-content">
                                <h4>04</h4>
                                <p>Units</p>
                            </div>
                        </div>
                    </section>
                    {/* data chart area  */}
                    <section className="data-chart-area">
                        <div className="row">
                            <div className="col-xl-7">
                                <div className="chart-wrap">
                                    <div id="chartdiv"/>
                                </div>
                            </div>
                            <div className="col-xl-5">
                                <div className="data-table">
                                    <div className="data-table-heading">
                                        <h5>Last S Maintenance Requests &amp; Concerns</h5>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Title</th>
                                                <th className="text-end">Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr className="tr-bg">
                                                <td>08/30/2021</td>
                                                <td>Test name who submit</td>
                                                <td className="text-end"><i className="fas fa-eye"/></td>
                                            </tr>
                                            <tr>
                                                <td>08/30/2021</td>
                                                <td>Checking Maintenance Ticket form Tenant End</td>
                                                <td className="text-end"><i className="fas fa-eye"/></td>
                                            </tr>
                                            <tr className="tr-bg">
                                                <td>08/30/2021</td>
                                                <td>Checking from Admin to Tenant</td>
                                                <td className="text-end"><i className="fas fa-eye-slash"/></td>
                                            </tr>
                                            <tr>
                                                <td>08/30/2021</td>
                                                <td>Test name who submit</td>
                                                <td className="text-end"><i className="fas fa-eye"/></td>
                                            </tr>
                                            <tr className="tr-bg">
                                                <td>08/30/2021</td>
                                                <td>Ice Maker Stopped Functioning</td>
                                                <td className="text-end"><i className="fas fa-eye"/></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="data-table-pagination">
                                        <div className="rows-per-page">
                                            <h6>Rows per page:</h6>
                                            <select>
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                                <option>6</option>
                                                <option>7</option>
                                                <option>8</option>
                                                <option>9</option>
                                                <option>10</option>
                                            </select>
                                        </div>
                                        <div className="pagination-part">
                                            <h6>1-5 of 13</h6>
                                            <span><i className="fas fa-angle-left"/> <i className="fas fa-angle-right"/></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* order table area  */}
                    <section className="order-table-area">
                        <div className="row table-heading align-items-center">
                            <div className="col-lg-9">
                                <h5>Amazon vendor order processing</h5>
                            </div>
                            <div className="col-lg-3">
                                <div className="table-search">
                                    <input type="search" name id placeholder="Search"/>
                                    <i className="fas fa-search"/>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>Order ID</th>
                                    <th className="status text-center">Status</th>
                                    <th className="sku-qnt text-center">SKU Quantity</th>
                                    <th className="item-qnt text-center">Item Quantity</th>
                                    <th className="order-value text-center">Order Value</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="order-id">#16512</td>
                                    <td className="status text-center"><span className="tbl-btn">Approved</span></td>
                                    <td className="sku-qnt text-center">2</td>
                                    <td className="item-qnt text-center">350</td>
                                    <td className="order-value text-center">$3595.79</td>
                                </tr>
                                <tr>
                                    <td className="order-id">#16512</td>
                                    <td className="status text-center"><span className="tbl-btn btn2">Pending</span>
                                    </td>
                                    <td className="sku-qnt text-center">2</td>
                                    <td className="item-qnt text-center">350</td>
                                    <td className="order-value text-center">$3595.79</td>
                                </tr>
                                <tr>
                                    <td className="order-id">#16512</td>
                                    <td className="status text-center"><span className="tbl-btn btn2">Pending</span>
                                    </td>
                                    <td className="sku-qnt text-center">2</td>
                                    <td className="item-qnt text-center">350</td>
                                    <td className="order-value text-center">$3595.79</td>
                                </tr>
                                <tr>
                                    <td className="order-id">#16512</td>
                                    <td className="status text-center"><span className="tbl-btn">Approved</span></td>
                                    <td className="sku-qnt text-center">2</td>
                                    <td className="item-qnt text-center">350</td>
                                    <td className="order-value text-center">$3595.79</td>
                                </tr>
                                <tr>
                                    <td className="order-id">#16512</td>
                                    <td className="status text-center"><span className="tbl-btn btn2">Pending</span>
                                    </td>
                                    <td className="sku-qnt text-center">2</td>
                                    <td className="item-qnt text-center">350</td>
                                    <td className="order-value text-center">$3595.79</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                    {/* accordion area  */}
                    <section className="accordion-area">
                        <div className="accordion accordion-flush" id="accordionFlushExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingOne">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                                            aria-expanded="false" aria-controls="flush-collapseOne">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" className="accordion-collapse collapse"
                                     aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">Lorem ipsum dolor sit amet consectetur adipisicing
                                        elit. Libero atque ullam at sint possimus impedit veritatis quos, veniam odit
                                        culpa velit earum, officiis excepturi necessitatibus!
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingTwo">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo"
                                            aria-expanded="false" aria-controls="flush-collapseTwo">
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                    </button>
                                </h2>
                                <div id="flush-collapseTwo" className="accordion-collapse collapse"
                                     aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">Lorem ipsum, dolor sit amet consectetur adipisicing
                                        elit. Magni beatae rerum unde non molestias consequuntur doloremque, deleniti,
                                        placeat alias velit dolor repudiandae? Velit dignissimos voluptatem tempora,
                                        voluptas necessitatibus facilis hic asperiores sit voluptate temporibus officia!
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingThree">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#flush-collapseThree"
                                            aria-expanded="false" aria-controls="flush-collapseThree">
                                        When an unknown printer took a galley of type and scrambled it to make a type
                                        specimen book.
                                    </button>
                                </h2>
                                <div id="flush-collapseThree" className="accordion-collapse collapse"
                                     aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">Lorem ipsum dolor sit amet, consectetur adipisicing
                                        elit. Molestias quasi possimus incidunt numquam accusamus et deserunt tenetur
                                        expedita quisquam perspiciatis dignissimos, debitis beatae nostrum hic, odit,
                                        itaque sapiente repudiandae fugit! Id in, maxime tempore reiciendis veritatis
                                        harum rerum deserunt sapiente perferendis debitis ab cupiditate neque.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingFour">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#flush-collapseFour"
                                            aria-expanded="false" aria-controls="flush-collapseFour">
                                        It has survived not only five centuries.
                                    </button>
                                </h2>
                                <div id="flush-collapseFour" className="accordion-collapse collapse"
                                     aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">Lorem ipsum dolor sit amet, consectetur adipisicing
                                        elit. Molestias quasi possimus incidunt numquam accusamus et deserunt tenetur
                                        expedita quisquam perspiciatis dignissimos, debitis beatae nostrum hic, odit,
                                        itaque sapiente repudiandae fugit! Id in, maxime tempore reiciendis veritatis
                                        harum rerum deserunt sapiente perferendis debitis ab cupiditate neque.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingFive">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#flush-collapseFive"
                                            aria-expanded="false" aria-controls="flush-collapseFive">
                                        But also the leap into electronic typesetting, remaining essentially unchanged.
                                    </button>
                                </h2>
                                <div id="flush-collapseFive" className="accordion-collapse collapse"
                                     aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">Lorem ipsum dolor sit amet, consectetur adipisicing
                                        elit. Molestias quasi possimus incidunt numquam accusamus et deserunt tenetur
                                        expedita quisquam perspiciatis dignissimos, debitis beatae nostrum hic, odit,
                                        itaque sapiente repudiandae fugit! Id in, maxime tempore reiciendis veritatis
                                        harum rerum deserunt sapiente perferendis debitis ab cupiditate neque.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="bottom-gap"/>
                </div>
                <footer className={`footer-area ${showFooter ? 'active' : ''}`}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xxl-4 order-xxl-1">
                                <ul className="footer-menu">
                                    <li><a href="#">Terms &amp; Conditions</a></li>
                                    <li><a href="#">Privacy Policy</a></li>
                                </ul>
                            </div>
                            <div className="col-xxl-4 order-xxl-3">
                                <ul className="footer-text-list">
                                    <li>Frature Request/Change Log</li>
                                    <li> Version: 1.1.0</li>
                                </ul>
                            </div>
                            <div className="col-xxl-4 order-xxl-2">
                                <p className="copyright">Copyright 2021. All Rights Serserved by Rent Blaze</p>
                            </div>
                        </div>
                        <div className="row footer-pera justify-content-center">
                            <div className="col-lg-8">
                                <p className="text-center">Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                    Molestiae, qui doloribus repudiandae, excepturi ex optio aliquid quaerat dolorem
                                    numquam eligendi voluptatem laborum in amet, id earum quasi cum saepe magni!</p>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>

        </div>
    )
}

import React from 'react';
import {createForum} from '@peerboard/core';
import axios from "axios";
import {Helmet} from "react-helmet";
import { withRouter } from "../../router/withRouter";


// Settings -> Hosting -> Board ID
const boardID = '288855';
// Settings -> Hosting -> Path Prefix
const pathPrefix = '/community';

// Somewhere in your routing configuration. Don't use exact attribute - you should allow all nested routes
// <Route path="<PATH PREFIX>" component={PageWithPeerboard} />

class PeerboardCommunity extends React.Component {
    constructor() {
        super();
        this.handleDashboardClick = this.handleDashboardClick.bind(this);
        this.handleAdminClick = this.handleAdminClick.bind(this);
    }
    containerRef = React.createRef();
    state = {
        forum: null,
        error: null,
        loading: true,
    };

    handleDashboardClick() {
        this.props.navigate('/');
    }

    handleAdminClick() {
        this.props.navigate('/admin')
    }

    //
    async createPeerBoard() {
        const options = {

            prefix: pathPrefix,

            // Recommended setting so that the forum container
            // will always occupy all available space
            minHeight: window.innerHeight,

            // Update your page title according to the forum state
            onTitleChanged: title =>
                window.document.title = 'Your title ' + title,

            // You can remove the loaded and forum will be showed to the user
            onReady: () => this.setState({
                loading: false,
            }),

            onFail: () => this.setState({
                error: "Failed to load forum...",
            }),
        };

        // Optional. Do this only if you want to implement seamless login https://community.peerboard.com/post/183197627?category=2097967386
        // Add auth token so the user will be authenticated when community is loaded
        //options.jwtToken = await axios.post('https://api.myapp.com/create-token-for-peerboard')

        this.forum = await createForum(boardID, this.containerRef.current, options);
    }

    componentDidMount() {
        this.createPeerBoard().catch(err => this.setState({
            error: err.message,
        }));
    }

    componentWillUnmount() {
        console.log(this.forum);
        if (this.forum) this.forum.destroy()
    }

    render() {
        return (
            <div>
                <div>
                    <div className="main-dashboard">
                        <Helmet>
                            <link rel="stylesheet" href="/assets/css/main.css"/>
                            <link rel="stylesheet" href="/assets/css/fontawesome-all.min.css"/>
                            <link rel="stylesheet" href="/assets/css/bootstrap.min.css"/>
                            <link rel="stylesheet" href="/assets/css/slick.css"/>
                            <link rel="stylesheet" href="/assets/css/responsive.css"/>
                        </Helmet>
                        <div className="sidebar collapsed">
                            <i className="fas fa-times"/>
                            <div className="logo-wrap">
                                <div className="logo-inner light-logo">
                                    <img className="logo" src="/assets/img/logo-light.png" alt=""/>
                                    <img className="logo-text" src="/assets/img/logo-text-light.png" alt=""/>
                                </div>
                                <div className="logo-inner dark-logo">
                                    <img className="logo" src="/assets/img/logo-dark.png" alt=""/>
                                    <img className="logo-text" src="/assets/img/logo-text-dark.png" alt=""/>
                                </div>
                            </div>
                            <div className="sidebar-menu-wrap">
                                <div className="menu-collapse-trigger">
                                    <span/>
                                    <span/>
                                    <span/>
                                </div>
                                <ul className="navigation">
                                    <li><a href="#" onClick={this.handleDashboardClick}><span className="menu-icon"><img
                                        src="/assets/img/sidebar/menu-icon1.png" alt=""/></span> <span
                                        className="menu-text">Dashboard</span></a></li>
                                    <li><a href="#"><span className="menu-icon"><img
                                        src="/assets/img/sidebar/menu-icon2.png"
                                        alt=""/></span> <span
                                        className="menu-text">Leases</span></a></li>
                                    <li><a href="#" onClick={this.handleAdminClick}><span
                                        className="menu-icon"><img src="/assets/img/sidebar/menu-icon2.png"
                                                                   alt=""/></span> <span
                                        className="menu-text">Admin</span></a></li>
                                    <li><a href="#"><span className="menu-icon"><img
                                        src="/assets/img/sidebar/menu-icon3.png"
                                        alt=""/></span> <span
                                        className="menu-text">Tenants</span></a></li>
                                    <li><a href="#"><span className="menu-icon"><img
                                        src="/assets/img/sidebar/menu-icon4.png"
                                        alt=""/></span> <span
                                        className="menu-text">Finance</span></a></li>
                                    <li><a href="#"><span className="menu-icon"><img
                                        src="/assets/img/sidebar/menu-icon5.png"
                                        alt=""/></span> <span
                                        className="menu-text">Calendar</span></a></li>
                                    <li><a href="#"><span className="menu-icon"><img
                                        src="/assets/img/sidebar/menu-icon6.png"
                                        alt=""/></span> <span
                                        className="menu-text">Issue Tracker</span></a></li>
                                    <li><a href="#"><span className="menu-icon"><img
                                        src="/assets/img/sidebar/menu-icon7.png"
                                        alt=""/></span> <span
                                        className="menu-text">Assets</span></a></li>
                                    <li><a href="#"><span className="menu-icon"><img
                                        src="/assets/img/sidebar/menu-icon8.png"
                                        alt=""/></span> <span
                                        className="menu-text">Warranties &amp; Insurance</span></a></li>
                                    <li><a href="#"><span className="menu-icon"><img
                                        src="/assets/img/sidebar/menu-icon9.png"
                                        alt=""/></span> <span
                                        className="menu-text">Capital Improvements</span></a></li>
                                    <li><a href="#"><span className="menu-icon"><img
                                        src="/assets/img/sidebar/menu-icon10.png"
                                        alt=""/></span> <span
                                        className="menu-text">Vendors</span></a></li>
                                    <li><a href="#"><span className="menu-icon"><img
                                        src="/assets/img/sidebar/menu-icon11.png"
                                        alt=""/></span> <span
                                        className="menu-text">Front Desk</span></a></li>
                                    <li><a href="#"><span className="menu-icon"><img
                                        src="/assets/img/sidebar/menu-icon12.png"
                                        alt=""/></span> <span
                                        className="menu-text">Security</span></a></li>
                                    <li><a href="#"><span className="menu-icon"><img
                                        src="/assets/img/sidebar/menu-icon13.png"
                                        alt=""/></span> <span
                                        className="menu-text">Staff</span></a></li>
                                    <li><a href="#"><span className="menu-icon"><img
                                        src="/assets/img/sidebar/menu-icon14.png"
                                        alt=""/></span> <span
                                        className="menu-text">Communication</span></a></li>
                                    <li><a href="#"><span className="menu-icon"><img
                                        src="/assets/img/sidebar/menu-icon15.png"
                                        alt=""/></span> <span
                                        className="menu-text">Units</span></a></li>
                                    <li><a href="#"><span className="menu-icon"><img
                                        src="/assets/img/sidebar/menu-icon16.png"
                                        alt=""/></span> <span
                                        className="menu-text">Files</span></a></li>
                                    <li><a href="#"><span className="menu-icon"><img
                                        src="/assets/img/sidebar/menu-icon17.png"
                                        alt=""/></span> <span
                                        className="menu-text">Reports</span></a></li>
                                    <li><a href="#"><span className="menu-icon"><img
                                        src="/assets/img/sidebar/menu-icon18.png"
                                        alt=""/></span> <span
                                        className="menu-text">Activity Stream</span></a></li>
                                    <li><a href="#"><span className="menu-icon"><img
                                        src="/assets/img/sidebar/menu-icon19.png"
                                        alt=""/></span> <span
                                        className="menu-text">System Seetings</span></a></li>
                                    <li><a href="#" className="active"><span
                                        className="menu-icon"><img src="/assets/img/sidebar/menu-icon20.png"
                                                                   alt=""/></span> <span
                                        className="menu-text">Community Forum &amp; User Guides</span></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="main-content collapsed">
                            <section className="header-bar">
                                <div className="row align-items-center">
                                    <div className="col-xl-3 col-md-12 col-2">
                                        <div className="property-item">
                                            <div className="property-img">
                                                <img src="/assets/img/property.png" alt=""/>
                                            </div>
                                            <div className="property-select">
                                                <select>
                                                    <option>New Heights</option>
                                                    <option>New Heights2</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mobile-logo">
                                            <img className="img-fluid logo-light" src="/assets/img/logo-light.png"
                                                 alt=""/>
                                            <img className="img-fluid logo-dark" src="/assets/img/logo-dark.png"
                                                 alt=""/>
                                        </div>
                                    </div>
                                    <div className="col-xl-9 col-md-12 col-10">
                                        <div className="header-bar-right">
                                            <ul className="header-icons">
                                                <li className="dropdown">
                                                    <a className="dropdown-toggle" href="#" role="button"
                                                       id="dropdownMenuLink1"
                                                       data-bs-toggle="dropdown" aria-expanded="false">
                                                        <img src="/assets/img/topbar-icon1.png" alt=""/>
                                                    </a>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink1">
                                                        <span className="triangle-arrow"/>
                                                    </div>
                                                </li>
                                                <li className="dropdown">
                                                    <a className="dropdown-toggle" href="#" role="button"
                                                       id="dropdownMenuLink2"
                                                       data-bs-toggle="dropdown" aria-expanded="false">
                                                        <img src="/assets/img/topbar-icon2.png" alt=""/>
                                                    </a>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink2">
                                                        <span className="triangle-arrow"/>
                                                    </div>
                                                </li>
                                                <li className="dropdown">
                                                    <a className="dropdown-toggle" href="#" role="button"
                                                       id="dropdownMenuLink3"
                                                       data-bs-toggle="dropdown" aria-expanded="false">
                                                        <img src="/assets/img/topbar-icon3.png" alt=""/>
                                                    </a>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink3">
                                                        <span className="triangle-arrow"/>
                                                    </div>
                                                </li>
                                                <li className="dropdown right">
                                                    <a className="dropdown-toggle" href="#" role="button"
                                                       id="dropdownMenuLink4"
                                                       data-bs-toggle="dropdown" aria-expanded="false">
                                                        <img src="/assets/img/topbar-icon4.png" alt=""/>
                                                    </a>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink4">
                                                        <span className="triangle-arrow"/>
                                                    </div>
                                                </li>
                                            </ul>
                                            <div className="account-details">
                                                <div className="account-author">
                                                    <img src="/assets/img/author.png" alt=""/>
                                                </div>
                                                <select>
                                                    <option>Anddy’s Makeover</option>
                                                    <option>Anddy’s Makeover2</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div ref={this.containerRef}></div>
                        </div>
                        {/*<footer className={`footer-area ${showFooter ? 'active' : ''}`}>*/}
                    </div>

                </div>
            </div>
        );
    }
}

export default withRouter(PeerboardCommunity);

import React from "react";
import {LoginPage} from "./pages/LoginPage";
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import {PortalDashboardPage} from "./pages/portal/PortalDashboardPage";
import {AdminDashboardPage} from "./pages/admin/AdminDashboardPage";
import PeerboardCommunity from "./pages/portal/PeerboardCommunity";

const App = () => (
  <BrowserRouter>
    <Routes>
        <Route path="/" element={<PortalDashboardPage/>}/>
        <Route path="/admin" element={<AdminDashboardPage/>}/>
        <Route path="/login" element={<LoginPage/>}/>
        <Route path="/community" element={<PeerboardCommunity/>}>
            <Route path="community/*" element={<PeerboardCommunity/>}/>
        </Route>
        <Route path="/portal/dashboard" element={<PortalDashboardPage/>}/>
    </Routes>
  </BrowserRouter>
)

export default App;
